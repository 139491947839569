import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box, Span } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">development</Text>
                <Title variant="secSm" className="my-4">
                  Carbon Emissions{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  The 
                  <a href="https://sdgs.un.org/goals" target="_blank"> United Nations Sustainable Development Goals </a>
                  are the blueprint to achieve a better and more sustainable future for all. The goals
                  address the global challenges we face, including poverty, inequality, climate change, environmental degradation, peace and justice.
                  In our group of developers, we designed and developed a web product based on 
                  <a href="https://sdgs.un.org/goals/goal13" target="_blank"> Goal 13 of Climate Action.</a>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  Front-End Developer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Sep 2020 - Dec 2020
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://uw-info442c-au20.github.io/Vroom/" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Live Work</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row style={{marginTop: -20}}>
              <Col xs="7" className="mb-2" style={{marginTop: -20}}>
                  <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1618566718/Carbon%20Emissions/SDG_Logo.png"} alt="sustainable development goals logo" className="img-fluid w-100" />
              </Col>
              <Col xs="5" className="mb-2">
                  <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1618566716/Carbon%20Emissions/Climate_Action_GIF.gif"} alt="climate action logo gif" className="img-fluid w-75" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: -60}}>
              Project Process
            </Title>
                <Title variant="cardBig" className="mb-4" style={{marginTop: -30}}>
                  01. Problem Statement
                </Title>
                <Text variant="p" className="mb-4">
                  The main problem we want to address is how we can help people identify transportation methods that utilize the least amount of fossil
                  fuels to help reduce the impacts of climate change. More specifically, we want to focus on addressing that issue locally in Seattle whether
                  that be through better utilizing the metro system, carpooling, biking, or even just pushing for more electric vehicle use around the city.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  02. Functional Requirements
                </Title>
                <Text variant="p" className="mb-4">
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 3}}>
                    <li>Information about carbon emissions when using private vs public transportation</li>
                    <li>Keep track of how much carbon emissions are emitted when the user uses private transportation</li>
                    <li>Resources page that contains information specific to the greater Seattle area</li>
                  </ul>
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  03. Wireframes
                </Title>
                <Row>
                  <Col xs="3" className="mb-5">
                    <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                      Information Page
                    </Title>
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619089387/Carbon%20Emissions/General_Info_Page.jpg"} className="img-fluid w-100" />
                  </Col>
                  <Col xs="3" className="mb-5">
                    <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                      Carbon Emissions Calculator
                    </Title>
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619089387/Carbon%20Emissions/Carbon_Emissions_Calculator.jpg"} className="img-fluid w-100" />
                  </Col>
                  <Col xs="3" className="mb-5">
                    <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                      Resources Page
                    </Title>
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619089386/Carbon%20Emissions/Resources_Page.jpg"} className="img-fluid w-100" />
                  </Col>
                  <Col xs="3" className="mb-5">
                    <Title variant="cardSm" className="mb-3" style={{marginTop: 10}}>
                      Profile Page
                    </Title>
                    <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619089387/Carbon%20Emissions/Profile_Page.jpg"} className="img-fluid w-100" />
                  </Col>
                </Row>
                <Text variant="p" className="mb-4" style={{marginTop: -30}}>
                  Note: Due to changes in our vision for the project, we converted our original mobile layout to a desktop layout.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  04. Architecture and Specifications
                </Title>
                <Text variant="p" className="mb-4">
                  <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 3}}>
                    <li>Architectural Style: Client/server</li>
                    <li>Code Organization: Each page has their own JavaScript file and utilizes a common CSS style sheet</li>
                    <li>Framework: Leveraging features from React</li>
                    <li>Hosting: Solution is hosted on GitHub Pages</li>
                    <li>Unit Test: Mileage calculator functions correctly</li>
                  </ul>
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  05. Testing Protocol
                </Title>
                <Text variant="p" className="mb-4">
                  <div style={{marginBottom: 20}}>
                    Initial Testing: We will make sure that pages are without typos and the carbon emissions calculator is functional 
                    without errors.
                  </div>
                  <div style={{marginBottom: 20}}>
                    User Testing: We created Google Form surveys and conducted usability testing where users are told to explore
                    certain features and provide written feedback. We have taken that feedback to improve upon our website further.
                  </div>
                  <div style={{marginBottom: 20}}>
                    Bugs Priority: We plan to fix bugs that affect our biggest features such as the carbon emissions calculator.
                    After that, we will focus on any bugs related to display and usability.
                  </div>
                  <div style={{marginBottom: 60}}>
                    Re-testing Solution: We plan to test the website ourselves again based on our testing protocol before pushing
                    the changes to the live site. If we discover any new bugs after another run-through of the website, we will
                    fix those further and push the changes. We will repeat this process until all bugs are fixed. Completion of
                    this step will finalize our website.
                  </div>
                </Text>
                <Title variant="cardBig" className="mb-4">
                  05. Outcomes
                </Title>
                <Text variant="p" className="mb-4">
                  Throughout this process, I applied social psychology, computer-supported collaborative work, and project management 
                  to a team-based project. I was able to complete a series of milestones and final project deliverables centered 
                  on the design and implementation of a functioning software product. I was reminded that designing an application 
                  easily usable by everyone requires a lot of planning and testing. User testing absolutely necessary to find out how
                  users will interact with our work and can reveal issues we never knew existed.
                </Text>
                <Text variant="p" className="mb-4">
                  If our team had the funding necessary to continue this project, we would develop it further into a navigation 
                  application where users can find directions to their destinations while keeping track of their carbon emissions output.
                  We want to be able to give users additional features and resources to help them learn more about carbon emissions.
                </Text>
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/legistar-redesign">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Legistar Redesign
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
